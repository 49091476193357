import React, { useEffect, useState } from 'react';
import { Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useParams } from 'react-router-dom';
import authenticatedFetcher from '../../services/fetcher';
import LoadingSpinner from '../LoadingSpinner';
import { toast } from 'react-toastify';

export default function CustomModalImportCourseAssistants(props) {
	const { id } = useParams();
	const { toggle, open } = props;

	const [loading, setLoading] = useState(true);
	const [courseData, setCourseData] = useState();
	const [assistants, setAssistants] = useState('');
	const [validatedData, setValidatedData] = useState();

	const handleChange = ({ target: { value } }) => {
		setAssistants(value);
		setValidatedData(undefined);
	};

	const getCourseInfo = () => {
		authenticatedFetcher({
			path: `/courses/${id}`,
			method: 'GET'
		})
			.then((response) => {
				if (response.error) {
					toast.error(response.error_data.data);
					return;
				}
				setCourseData(response);
			})
			.catch((error) => toast.error(error.error_data.data))
			.finally(() => setLoading(false));
	};

	const handleValidateData = () => {
		setLoading(true);
		authenticatedFetcher({
			path: `/courses/attendants/validate-attendants/`,
			method: 'POST',
			body: {
				identity_documents: assistants,
				course_pk: id
			}
		})
			.then((response) => {
				if (response.error) {
					toast.error(response.error_data.data);
					return;
				}
				setValidatedData(response);
			})
			.catch((error) => toast.error(error.error_data.data))
			.finally(() => setLoading(false));
	};

	const handleSaveData = () => {
		if (!validatedData) return;
		setLoading(true);
		authenticatedFetcher({
			path: `/courses/attendants/save-attendants-assisted/`,
			method: 'POST',
			body: {
				attendants: validatedData.attendants_not_assisted,
				not_found_attendants: validatedData.not_found.concat(validatedData.not_attendants_assisted),
				course: id,
				not_member_assisted: validatedData.not_member_assisted
			}
		})
			.then((response) => {
				if (response.error) {
					toast.error(response.error_data.data);
					return;
				}
				setAssistants('');
				setValidatedData(undefined);
				setTimeout(() => window.location.reload(), 5000);
				toast.success(
					`${response.updated} asistentes actualizados correctamente y ${response.created} asistentes creados.`
				);
			})
			.catch((error) => toast.error(error.error_data.data))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		getCourseInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Modal size="sm" toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				Importar asistentes a curso: {courseData?.title}
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div>
							{loading && (
								<div className="d-flex justify-content-center">
									<LoadingSpinner show={loading} />
								</div>
							)}
							{!loading && (
								<>
									<label
										htmlFor="identity_documents"
										style={{ textTransform: 'none', color: 'gray' }}>
										Escribe un DNI/NIE/Documento de identidad por línea para especificar quien ha
										asistido:
									</label>
									<textarea
										id="identity_documents"
										className="form-control"
										style={{ width: '100%', height: 200, marginTop: 5 }}
										value={assistants}
										onChange={handleChange}
									/>
									{validatedData && (
										<>
											<ul style={{ marginTop: 10 }}>
												<li>Nº de líneas: {validatedData.lines}</li>
												<li>
													Nº inscritos que asistieron:{' '}
													{validatedData.attendants_already_assisted.length}
												</li>
												<li>
													Nº inscritos que se marcarán como 'Asiste':{' '}
													{validatedData.attendants_not_assisted.length}
												</li>
												<li>
													Nº de NO inscritos que se inscribirán automáticamente y se marcarán
													como 'Asiste': {validatedData.not_attendants_assisted.length}
													<ul>
														{validatedData.not_attendants_assisted.map((item) => {
															return <li key={item}>{item}</li>;
														})}
													</ul>
												</li>
												<li>
													Nº de inscritos NO mimbros que se marcarán como 'Asiste':{' '}
													{validatedData.not_member_assisted.length}
												</li>
												<li>
													Nº de no encontrados que se ignorarán:{' '}
													{validatedData.not_found.length}
													<ul>
														{validatedData.not_found.map((item) => {
															return <li key={item}>{item}</li>;
														})}
													</ul>
												</li>
											</ul>
										</>
									)}
									<div
										style={{
											width: '100%',
											display: 'flex',
											gap: 10,
											justifyContent: 'flex-end',
											marginTop: 10
										}}>
										<button type="button" className="btn btn-primary" onClick={handleValidateData}>
											Validar datos
										</button>
										<button
											type="button"
											className="btn btn-primary"
											onClick={handleSaveData}
											disabled={
												!validatedData ||
												(!validatedData.attendants_not_assisted.length &&
													!validatedData.not_found.length &&
													!validatedData.not_attendants_assisted.length &&
													!validatedData.not_member_assisted.length)
											}>
											Marcar Asiste
										</button>
									</div>
								</>
							)}
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}

CustomModalImportCourseAssistants.defaultProps = {
	toggle: () => {}
};
