import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import fetcher from '../services/fetcher';
import { DynamicFormLayout } from './DynamicForm';
import WithFormConfig from '../hocs/WithFormConfig';
import { formatFormFields } from '../services/commonServices';
import { toastError, toastSuccess } from '../utils/ToastConfig';
import { useTranslation } from 'react-i18next';

const DEFAULT_ACTIONS = [
	// TODO: borrar cuando la API este actualizada con las actions para cada formulario.
	{
		key: 'edit',
		uri: '/persons/',
		method: 'PUT'
	},
	{
		key: 'read',
		uri: '/persons/',
		method: 'GET'
	},
	{
		key: 'delete',
		uri: '/persons/',
		method: 'DELETE'
	},
	{
		key: 'write',
		uri: '/persons/',
		method: 'POST'
	}
];

const Create = ({
	config,
	toggle,
	fetchData,
	data,
	filterMode,
	fieldWithParams,
	withoutRelateds,
	mode = 'modal',
	returnRes
}) => {
	const { actions = DEFAULT_ACTIONS } = config;
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [errors, setErrors] = useState({});
	const [massiveCreationData, setMassiveCreationData] = useState();
	const [t] = useTranslation(['app', 'map']);

	async function submitHandler(data) {
		if (filterMode) {
			fetchData(data);
			return toggle();
		}

		try {
			const newData = formatFormFields(data);
			if (newData['doc'] && !newData['doc_url']) newData['doc_url'] = newData['doc'];
			const [write = false] = actions.filter((action) => action.key === 'write');

			if (write) {
				setLoadingSubmit(true);
				const mainRes = await fetcher({ path: write.uri, method: write.method, body: newData })
					.then((res) => {
						if (!res.error_data && !res.error) {
							toastSuccess(t('app:save'));
							if (massiveCreationData) return res;
						}

						if (!res.error) {
							mode !== 'form' && toggle();
							fetchData(returnRes ? res : undefined);
							setErrors({});
						} else {
							if (res.error_data && Array.isArray(res.error_data.non_field_errors))
								res.error_data.non_field_errors.map((item) => toastError(item));
							else toastError(res.error);

							setErrors(res.error_data);
						}
						return null;
					})
					.catch((error) => {
						toastError(`Ocurrió un error inesperado, ${error?.message || ''}`);
					});

				if (mainRes) {
					let requests = [];
					Object.values(massiveCreationData).forEach(({ tabData, tabConfig, tabRelatedField }) => {
						const gridWriteCfg = tabConfig.actions.find((item) => item.key === 'write');
						tabData.forEach((item) => {
							const extraInfo = tabConfig.extraParams ? tabConfig.extraParams : {};
							requests.push(
								fetcher({
									path: gridWriteCfg.uri,
									method: 'POST',
									body: {
										...item,
										...extraInfo,
										[tabRelatedField]: mainRes.pk || mainRes.id
									}
								}).then((res) => res && !('error' in res))
							);
						});
					});
					const valid = await Promise.all(requests).then((allreq) => !allreq.some((item) => item === false));
					if (!valid) toastError(`Ocurrió un error en la creación masiva!`);
					else {
						mode !== 'form' && toggle();
						fetchData(returnRes ? mainRes : undefined);
						setErrors({});
					}
				}
				setLoadingSubmit(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const saveMassiveCreationData = (tabData, tabConfig, tabRelatedField, tabId) =>
		setMassiveCreationData((prev) => ({ ...prev, [tabId]: { tabData, tabConfig, tabRelatedField } }));

	const renderForm = () => (
		<DynamicFormLayout
			config={config}
			data={data}
			onSubmit={submitHandler}
			withoutButtonsBar
			withoutRelateds={withoutRelateds}
			isCreating={!filterMode}
			formErrors={errors}
			filterMode={filterMode}
			tabProcessingOptions={{
				loadDefaultData: !data,
				fieldWithParams
			}}
			saveMassiveCreationData={saveMassiveCreationData}
			loadingSubmit={loadingSubmit}
		/>
	);

	if (mode === 'form') return renderForm();

	return (
		<Modal isOpen toggle={toggle} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{config.name || 'Create Form'}
			</ModalHeader>
			<ModalBody>{renderForm()}</ModalBody>
		</Modal>
	);
};

Create.propTypes = {
	config: PropTypes.object.isRequired,
	toggle: PropTypes.func.isRequired,
	fetchData: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(['form', 'modal']),
	returnRes: PropTypes.bool
};

export default WithFormConfig(Create, true);
