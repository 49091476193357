import React, { Fragment, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Col, FormGroup, Label } from 'reactstrap';
import { Types } from '../../../utils/DynamicForm.config';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
//import Editor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';
import ckUploader from '../../../services/ckUploader';

import InputDescription from '../../InputUtils/InputDescription';

let handleChangeTimeout = null;

const TypeFormTextareaRich = ({
	label,
	value,
	required,
	errors,
	name,
	onChange,
	options,
	relatedData = {},
	isEditing = true,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	},
	alwaysEditableFields,
	editable,
	custom_tags_plugins
}) => {
	const [currentValue, setCurrentValue] = useState(value);
	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;
	const config =
		(options && options.custom_tags_plugins) || custom_tags_plugins
			? undefined
			: {
					fontSize: {
						options: [9, 11, 13, 'default', 17, 19, 21, 24, 48]
					},
					toolbar: {
						items: [
							'Cut',
							'Copy',
							'Paste',
							'PastePlainText',
							'PasteFromWord',
							'|',
							'undo',
							'redo',
							'|',
							'bold',
							'italic',
							'underline',
							'strikethrough',
							'|',
							'FontSize',
							'fontColor',
							'fontBackgroundColor',
							'|',
							'blockQuote',
							'link',
							'mailTo',
							'|',
							'bulletedList',
							'numberedList',
							'|',
							'alignment',
							'|',
							'ImageUpload',
							'mediaEmbed',
							'HtmlEmbed',
							'|',
							'Subtitle',
							'Warning',
							'sourceEditing',
							'Maximize'
						]
					}
			  };

	useEffect(() => {
		clearTimeout(handleChangeTimeout);
		handleChangeTimeout = setTimeout(() => onChange({ target: { name, value: currentValue } }), 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentValue]);

	const handleOnInit = (editor) => {
		if (!editor) return;
		editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
			return new ckUploader(loader);
		};
	};

	const handleChange = (event, editor) => {
		const data = editor.getData();
		setCurrentValue(data);
	};

	return (
		<FormGroup row>
			<Col {...labelColumn}>
				<Label for="form-2-last-name">
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				{isEditing && isEditable ? (
					<Fragment>
						<div className={`TextareaRich ${getInputStatus(required, value, errors)} ${relatedData.class}`}>
							<CKEditor
								editor={Editor}
								data={currentValue}
								onReady={handleOnInit}
								onChange={handleChange}
								config={config}
							/>
						</div>
						<PrintInputError errors={errors} />
						<InputDescription description={relatedData ? relatedData.description : ''} />
					</Fragment>
				) : (
					parse(value || '')
				)}
			</Col>
		</FormGroup>
	);
};
TypeFormTextareaRich.propTypes = Types.inputType;

export default TypeFormTextareaRich;
