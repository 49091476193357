import ModalExport from './CustomModalExportTest';
import ModalResult from './CustomModalResult';
import ModalPartnersCourse from './CustomModalsPartnersCourse';
import ModalImportDiscounts from './CustomModalImportDiscount';
import ModalOTSAssign from './CustomModalOTSAssign';
import CustomModalChangeIncidences from './CustomModalChangeIncidences';
import ModalSections from './CustomModalSections';
import Create from '../Create';
import ModalChangeInsuranceGroup from './CustomModalChangeInsuranceGroup';
import ModalImportEfpaMagazine from './CustomModalImportEfpaMagazine';
import CustomModalCreateMassiveEmail from './CustomModalCreateMassiveEmail';
import ModalImportCourseAssistants from './CustomModalImportCourseAssistants';
import ModalImportPollQuestions from "./CustomModalImportPollQuestions";


const CustomModals = {
	ModalExport,
	ModalResult,
	ModalChangeInsuranceGroup,
	ModalImportEfpaMagazine,
	ModalPartnersCourse,
	ModalImportDiscounts,
	ModalSections,
	'assign-ots-team': ModalOTSAssign,
	'change-indicences-status': CustomModalChangeIncidences,
	create: Create,
	CustomModalCreateMassiveEmail,
	ModalImportCourseAssistants,
	ModalImportPollQuestions
};

export default CustomModals;
