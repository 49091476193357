import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Col, FormGroup, Label } from 'reactstrap';
import { Types } from '../../../utils/DynamicForm.config';
import PrintInputError from '../../InputUtils/PrintInputError';

const TypeInlineInputs = (props) => {
	const {
		label,
		onChange,
		required,
		errors,
		style,
		isSLAField,
		labelColumn = {
			xs: '6',
			md: '2'
		},
		inputColumn = {
			xs: '6',
			md: '10'
		},
		inline_inputs,
		alwaysEditableFields
	} = props;
	const [localValues, setLocalValues] = useState({});
	const [isEditable, setIsEditable] = useState({});

	useEffect(() => {
		if (inline_inputs) {
			const initialValues = {};
			const editableFields = {};

			inline_inputs.forEach((input) => {
				initialValues[input.name] =
					input.type === 'custom'
						? undefined
						: input.label === 'ID'
						? input.value
						: props.originalData[input?.name || ''];
				onChange({ target: { value: initialValues[input.name], name: input.name } });

				if (Array.isArray(alwaysEditableFields) && alwaysEditableFields.includes(input.name)) {
					input.editable = true;
				}
				editableFields[input.name] = input.editable;
			});

			setLocalValues(initialValues);
			setIsEditable(editableFields);
		}
	}, [inline_inputs, alwaysEditableFields]);

	const handleChange = ({ target: { name, value } }) => {
		setLocalValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));
		onChange({ target: { value, name } });
	};

	return (
		<FormGroup tag="fieldset" row>
			{label !== false ? (
				<Col
					{...labelColumn}
					className={`${labelColumn.className} ${isSLAField && 'CustomContractesInfo-sla-text'}`}>
					<Label style={style} for="form-2-last-name">
						{required ? '*' : ''} {label}
					</Label>
				</Col>
			) : (
				''
			)}
			<Col
				{...inputColumn}
				className={`${inputColumn.className} ${isSLAField && 'CustomContractesInfo-sla-text regular'}`}>
				<div style={{ display: 'flex' }}>
					{inline_inputs &&
						inline_inputs.map((input) => {
							return (
								<Form.Control
									id={`form-2-${input.id}`}
									type={input.type}
									name={input.name}
									defaultValue={localValues[input.name] || ''}
									placeholder={input.placeholder}
									onChange={handleChange}
									disabled={!isEditable[input.name]}
									className="pl-0"
									style={{
										width: localValues[input.name] ? localValues[input.name].length * 11 : '100%'
									}}
								/>
							);
						})}
				</div>
				{/*<FormFeedback>{localValue}</FormFeedback>*/}
				<PrintInputError errors={errors} />
			</Col>
		</FormGroup>
	);
};
TypeInlineInputs.propTypes = Types.inputType;

TypeInlineInputs.defaultProps = {
	type: 'inline-inputs'
};

export default TypeInlineInputs;
