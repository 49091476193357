import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DynamicFormLayout } from '../../components/DynamicForm';
import WithFormConfig from '../../hocs/WithFormConfig';
import { getMethods, objectToUrlParams } from '../../services/commonServices';
import authenticatedFetcher from '../../services/fetcher';
import {toastError, toastSuccess} from '../../utils/ToastConfig';
import { useTranslation } from 'react-i18next';
import Message from '../../components/Message';
import { Button, Card } from 'reactstrap';
import htmlParse from "html-react-parser";


const CustomPlanificationsTemporalDistribution = (props) => {
	const [actions = {}] = useState(getMethods(props.config.actions));
	const { read, write, export: exportMethod } = actions || {};
	const [t] = useTranslation('app');
	const [isMounted, setIsMounted] = useState(false);
	const [showErrorMessage, handleErrorMessage] = useState();
	const [refreshCount, setRefreshCount] = useState(0);
	const [urlBody, setUrlBody] = useState();
	const history = useHistory();
	const [showTable, setShowTable] = useState(false);
	const [searchParams, setSearchParams] = useState({});
	const [errorMessage, setErrorMessage] = useState();
	const [canEdit, setCanEdit] = useState(true);
	const [genericData, setGenericData] = useState({});
	const nameVars = {
		"prefix": ["Prod", "CD", "CI", "CP", "CE", "Tot_Coste", "Res", "CF"],
		"total": ["SIE_Origen", "Pdt_Ano_Actual", "Pdt_Ano_uno", "Pdt_Ano_dos",
			"Pdt_Ano_tres", "Pdt_Ano_cuatro", "Pdt_Ano_resto", "Pdt_Total", "Total"],
		"ejercicio": ["SIE_Ejer", "Pdt_Ejer_Enero", "Pdt_Ejer_Febrero", "Pdt_Ejer_Marzo",
			"Pdt_Ejer_Abril", "Pdt_Ejer_Mayo", "Pdt_Ejer_Junio", "Pdt_Ejer_Julio",
			"Pdt_Ejer_Agosto", "Pdt_Ejer_Septiembre", "Pdt_Ejer_Octubre", "Pdt_Ejer_Noviembre",
			"Pdt_Ejer_Diciembre", "Pdt_Ejer_Total", "Ejer_Total"]
	}

	const calculateTotals = (data, pass_to_origen = true) => {
		nameVars["prefix"].map(prefix_var => {
			data[`${prefix_var}_Pdt_Ejer_Total`] = (data[`${prefix_var}_Pdt_Ejer_Enero`] +
				data[`${prefix_var}_Pdt_Ejer_Febrero`] + data[`${prefix_var}_Pdt_Ejer_Marzo`] +
				data[`${prefix_var}_Pdt_Ejer_Abril`] + data[`${prefix_var}_Pdt_Ejer_Mayo`] +
				data[`${prefix_var}_Pdt_Ejer_Junio`] + data[`${prefix_var}_Pdt_Ejer_Julio`] +
				data[`${prefix_var}_Pdt_Ejer_Agosto`] + data[`${prefix_var}_Pdt_Ejer_Septiembre`] +
				data[`${prefix_var}_Pdt_Ejer_Octubre`] + data[`${prefix_var}_Pdt_Ejer_Noviembre`] +
				data[`${prefix_var}_Pdt_Ejer_Diciembre`]);
			data[`${prefix_var}_Ejer_Total`] = data[`${prefix_var}_Pdt_Ejer_Total`] + data[`${prefix_var}_SIE_Ejer`];

			if( pass_to_origen ) {
				data[`${prefix_var}_Pdt_Ano_Actual`] = data[`${prefix_var}_Pdt_Ejer_Total`];
			}

			data[`${prefix_var}_Pdt_Total`] = (data[`${prefix_var}_Pdt_Ano_Actual`] +
				data[`${prefix_var}_Pdt_Ano_uno`] + data[`${prefix_var}_Pdt_Ano_dos`] +
				data[`${prefix_var}_Pdt_Ano_tres`] + data[`${prefix_var}_Pdt_Ano_cuatro`] +
				data[`${prefix_var}_Pdt_Ano_resto`]);
			data[`${prefix_var}_Total`] = data[`${prefix_var}_Pdt_Total`] + data[`${prefix_var}_SIE_Origen`];
		});

		return data;
	}

	const initializeGenericData = () => {
		let data = {}
		nameVars["prefix"].map(prefix_var => {
			nameVars["total"].map(name_var => {
				data[`${prefix_var}_${name_var}`] = 0;
			})
		});
		nameVars["prefix"].map(prefix_var => {
			nameVars["ejercicio"].map(name_var => {
				data[`${prefix_var}_${name_var}`] = 0;
			})
		});
		data = calculateTotals(data);
		setGenericData(data);
		return data;
	}

	useEffect(() => {
		initializeGenericData();
		if (!isMounted && history.location.search) {
			const urlParams = Object.fromEntries(new URLSearchParams(history.location.search));
			if (urlParams.dosearch) {
				handleSearch(urlParams, false);
			}
			setIsMounted(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.search]);

	const handleSearch = (data, saveParams = true) => {
		if (!read) return;
		const body = Object.entries(data).reduce((acc, [key, value]) => {
			if (typeof value === 'boolean' || value) return { ...acc, [key]: value };
			return acc;
		}, {});
		setSearchParams(body);
		setUrlBody(body);
		const searchParams = objectToUrlParams(body);
		if (saveParams)
			history.push({ search: searchParams });

		authenticatedFetcher({
			method: read.method,
			path: read.uri,
			body
		})
			.then((res) => {
				if (!res) return;
				setCanEdit(true);
				initializeGenericData();
				if ('error' in res) {
					setShowTable(false);
					setErrorMessage(res['error']);
					return handleErrorMessage(true);
				}
				setShowTable(true);
				let data = res.data ?? null;
				if (data) {
					setGenericData(data);
					setCanEdit(res.can_edit);
					calculateTotals(data);
					setRefreshCount((prev) => prev + 1);
					handleErrorMessage(false);
				}
			})
			.catch(() => handleErrorMessage(true));
	};

	const submitData = () => {
		if (!write) return;
		authenticatedFetcher({
			method: write.method,
			path: write.uri,
			body: {
				"search": searchParams,
				"data": genericData
			}
		})
		.then((res) => {
			if ('error' in res) {
				toastError(
					htmlParse(res.error),
					{ autoClose: false }
				);
			} else {
				toastSuccess(
					res.response,
					{ autoClose: 'fixed_message' in res ? !res.fixed_message : true },
					true
				);

				handleSearch(searchParams);
			}

			handleErrorMessage(false);
		})
		.catch(() => handleErrorMessage(true));
	};

	const updateData = (event) => {
		let fieldName = event.target.name;
		let fieldValue = convertToDecimal(event.target.value);

		genericData[fieldName] = fieldValue;
		console.log(fieldName +' ==> ' + fieldValue);
		setGenericData(calculateTotals(genericData, false));
		setRefreshCount((prev) => prev + 1);
	};

	const formatDecimal = (value, suffix = "", numDecimals = 2) => {
		let default_value = "0";
		let parsed_value = (value ? Intl.NumberFormat(
			"es-ES",
			{ maximumFractionDigits: numDecimals, minimumFractionDigits: numDecimals, useGrouping: false }
		).format(value) + suffix : default_value);

		return convertToDecimal(parsed_value.replace(",", "."));
	};

	const parseDecimal = (value, suffix = "", numDecimals = 2) => {
		let default_value = "0,00";
		let parsed_value = (value ? Intl.NumberFormat(
			"es-ES",
			{ maximumFractionDigits: numDecimals, minimumFractionDigits: numDecimals, useGrouping: true }
		).format(value) + suffix : default_value);

		if( parsed_value === "-0,00" ) {
			parsed_value = default_value;
		}
		return parsed_value;
	};

	const convertToDecimal = (value) => {
		return value === '' ? parseFloat("0.00") : parseFloat(value);
	};

	const renderTablePlaniTotal = () => {
		return (
			<Fragment>
				<div className="card-box listado-personas card planification-module mt-4 mb-3">
					<div className="table-responsive">
						<div className="card-header py-3">
							<div className="card-header--title font-size-lg">
								PLANIFICACIÓ TOTAL
							</div>
						</div>
						<table className="mb-0 mt-3 table table-hover table-bordered">
							{renderHeaderTablePlaniTotal()}
							<tbody>
								{renderRowsTablePlaniTotal()}
							</tbody>
						</table>
					</div>
					{canEdit && (
						<Fragment>
							<div className="card-footer">
								<div style={{ textAlign: "right", width: "100%"}}>
									<Button size="submitButton btn btn-secondary btn-planning"
										style={{ "border": "1px solid" }}
										onClick={() => submitData()}>
										{t('Desar')}
									</Button>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</Fragment>
		);
	}

	const renderHeaderTablePlaniTotal = () => {
		return (
			<Fragment>
				<thead>
					<tr>
						<td width="10%" className="bg-gray-300"></td>
						<td className="text-center td-blue-header" width="10%">
							<strong>SIE Origen { genericData["Origen_month"] !== 0 ? `- ${genericData["Origen_month"]}` : '' }</strong>
						</td>
						<td className="text-center td-blue-header" width="60%" colSpan="6">
							<strong>PENDENT</strong>
						</td>
						<td className="text-center td-blue-header" width="10%">
							<strong>TOTAL PENDENT</strong>
						</td>
						<td className="text-center td-blue-header" width="10%" >
							<strong>TOTAL</strong>
						</td>
					</tr>
					<tr>
						<td width="10%" className="bg-gray-300"></td>
						<td width="10%" className="bg-gray-300"></td>
						<td className="text-center td-blue-white" width="10%">
							<strong>Pendent Any actual</strong>
						</td>
						<td className="text-center td-blue-white" width="10%">
							<strong>Any + 1</strong>
						</td>
						<td className="text-center td-blue-white" width="10%">
							<strong>Any + 2</strong>
						</td>
						<td className="text-center td-blue-white" width="10%">
							<strong>Any + 3</strong>
						</td>
						<td className="text-center td-blue-white" width="10%">
							<strong>Any + 4</strong>
						</td>
						<td className="text-center td-blue-white" width="10%">
							<strong>Reste</strong>
						</td>
						<td width="10%" className="bg-gray-300"></td>
						<td width="10%" className="bg-gray-300"></td>
					</tr>
				</thead>
			</Fragment>
    	);
	}

	const renderRowsTablePlaniTotal = () => {
		let rows_keys = [
			{"name": "Prod", "vars": nameVars["total"].map(item => `Prod_${item}`)},
			{"name": "CD", "vars": nameVars["total"].map(item => `CD_${item}`)},
			{"name": "CI", "vars": nameVars["total"].map(item => `CI_${item}`)},
			{"name": "OCI", "vars":nameVars["total"].map(item => `CP_${item}`)},
			{"name": "CF", "vars":nameVars["total"].map(item => `CF_${item}`)},
			{"name": "CE", "vars": nameVars["total"].map(item => `CE_${item}`)},
			{"name": "Tot. Cost", "vars":nameVars["total"].map(item => `Tot_Coste_${item}`)},
			{"name": "Res", "vars": nameVars["total"].map(item => `Res_${item}`)}
		];
		return (
			<Fragment>
				{rows_keys.map((row_key) => {
					return (
						<tr>
							<td className="text-left bg-gray-300" style={{ height: '30px' }}>
								<span>{row_key["name"]}</span>
							</td>
							{row_key["vars"].map((row_var, index) => {
								return (
									<Fragment>
										{(canEdit && ([0, 1, 7, 8].indexOf(index) === -1)) ? (
											<td className="tdWithEvent text-right" style={{ height: '30px' }}>
												<input
													className="form-control input-text-wrapper input-planning-small text-right"
													type="number"
													name={row_var}
													value={formatDecimal(genericData[row_var])}
													onChange={event => updateData(event)}
												/>
											</td>
										) : (
											<td className="text-right bg-gray-300" style={{ height: '30px' }}>
												<span>{parseDecimal(genericData[row_var])}</span>
											</td>
										)}
									</Fragment>
								);
							})}
						</tr>
					);
				})}
			</Fragment>
		);
	}

	const renderTablePlaniEjercicio = () => {
		return (
			<Fragment>
				<div className="card-box listado-personas card planification-module mt-4 mb-3">
					<div className="table-responsive">
						<div className="card-header py-3">
							<div className="card-header--title font-size-lg">
								PLANIFICACIÓ EXERCICI
							</div>
						</div>
						<table className="mb-0 mt-3 table table-hover table-bordered">
							{renderHeaderTablePlaniEjercicio()}
							<tbody>
								{renderRowsTablePlaniEjercicio()}
							</tbody>
						</table>
					</div>
					{canEdit && (
						<Fragment>
							<div className="card-footer">
								<div style={{ textAlign: "right", width: "100%"}}>
									<Button size="submitButton btn btn-secondary btn-planning"
										style={{ "border": "1px solid" }}
										onClick={() => submitData()}>
										{t('Desar')}
									</Button>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</Fragment>
		);
	}

	const renderHeaderTablePlaniEjercicio = () => {
		return (
			<Fragment>
				<thead>
					<tr>
						<td width="10%" className="bg-gray-300"></td>
						<td className="text-center td-blue-header" width="10%">
							<strong>SIE Exercici { genericData["Exercici_month"] !== 0 ? `- ${genericData["Exercici_month"]}` : '' }</strong>
						</td>
						<td className="text-center td-blue-header" width="60%" colSpan="12">
							<strong>PENDENT ANY ACTUAL</strong>
						</td>
						<td className="text-center td-blue-header" width="10%">
							<strong>TOTAL PENDENT</strong>
						</td>
						<td className="text-center td-blue-header" width="10%" >
							<strong>TOTAL Exercici</strong>
						</td>
					</tr>
					<tr>
						<td width="10%" className="bg-gray-300"></td>
						<td width="10%" className="bg-gray-300"></td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Gener</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Febrer</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Març</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Abril</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Maig</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Juny</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Juliol</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Agost</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Setembre</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Octubre</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Novembre</strong>
						</td>
						<td className="text-center td-blue-white" width="5%">
							<strong>Desembre</strong>
						</td>
						<td width="10%" className="bg-gray-300"></td>
						<td width="10%" className="bg-gray-300"></td>
					</tr>
				</thead>
			</Fragment>
    	);
	}

	const renderRowsTablePlaniEjercicio = () => {
		let rows_keys = [
			{"name": "Prod", "vars": nameVars["ejercicio"].map(item => `Prod_${item}`)},
			{"name": "CD", "vars": nameVars["ejercicio"].map(item => `CD_${item}`)},
			{"name": "CI", "vars": nameVars["ejercicio"].map(item => `CI_${item}`)},
			{"name": "OCI", "vars":nameVars["ejercicio"].map(item => `CP_${item}`)},
			{"name": "CF", "vars":nameVars["ejercicio"].map(item => `CF_${item}`)},
			{"name": "CE", "vars": nameVars["ejercicio"].map(item => `CE_${item}`)},
			{"name": "Tot. Cost", "vars":nameVars["ejercicio"].map(item => `Tot_Coste_${item}`)},
			{"name": "Res", "vars": nameVars["ejercicio"].map(item => `Res_${item}`)}
		];
		return (
			<Fragment>
				{rows_keys.map((row_key) => {
					return (
						<tr>
							<td className="text-left bg-gray-300" style={{ height: '30px' }}>
								<span>{row_key["name"]}</span>
							</td>
							{row_key["vars"].map((row_var, index) => {
								return (
									<Fragment>
										{/*(canEdit && ([0, 13, 14].indexOf(index) === -1)) ? (
											<td className="tdWithEvent text-right" style={{ height: '30px' }}>
												<input
													className="form-control input-text-wrapper input-planning-small text-right"
													type="number"
													name={row_var}
													value={formatDecimal(genericData[row_var])}
													onChange={event => updateData(event)}
												/>
											</td>
										) : (
											<td className="text-right bg-gray-300" style={{ height: '30px' }}>
												<span>{parseDecimal(genericData[row_var])}</span>
											</td>
										)*/}
										<td className="text-right bg-gray-300" style={{ height: '30px' }}>
											<span>{parseDecimal(genericData[row_var])}</span>
										</td>
									</Fragment>
								);
							})}
						</tr>
					);
				})}
			</Fragment>
		);
	}

	return (
		<Fragment>
			<div className="planification-module">
				<DynamicFormLayout
					config={props.config}
					onSubmit={handleSearch}
					withoutButtonsBar
					withoutRelateds
					filterMode
					isSearcher
					tabProcessingOptions={{
						loadDefaultData: true,
					}}
					inputsAlign={
						{
							labelColumn: { sm: 6, md: 5, lg: 4 },
							inputColumn: { sm: 6, md: 7, lg: 8 }
						}
					}
				/>

				{showTable && !showErrorMessage ? (
					<Fragment>
						{renderTablePlaniTotal()}
						{renderTablePlaniEjercicio()}
					</Fragment>
				) : (
					showErrorMessage && (
						<Card className="mt-4 p-2">
							<Message type="error" className="m-0">
								{errorMessage}
							</Message>
						</Card>
					)
				)}
			</div>
		</Fragment>
	);
};

CustomPlanificationsTemporalDistribution.propTypes = {};

export default WithFormConfig(
    CustomPlanificationsTemporalDistribution,
    true,
    'planifications-temporal-distribution',
    { urlParametersFirst: true }
);
