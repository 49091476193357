import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import authenticatedFetcher from '../../services/fetcher';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import { MEDIA_BASE_URL } from '../../config';
import { DynamicForm } from '../DynamicForm';
import BasicTable from '../Ticketing/BasicTable';
import LoadingSpinner from '../LoadingSpinner';
import {useParams} from "react-router-dom";


export default function CustomModalImportPollQuestions(props) {
	const { toggle, open, fetchData } = props;
	const { id } = useParams();
	const [tableContent, setTableContent] = useState(null);
	const [formData, setFormData] = useState({});
	const [printImportButton, setPrintImportButton] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [t] = useTranslation('app');

	const handleLoadFiles = async () => {
		setTableContent(null);
		setPrintImportButton(false);
		const res = await authenticatedFetcher({
			path: '/poll/questions/import/previous/',
			method: 'POST',
			body: formData
		}).then((res) => {
			setTableContent({
				...res,
				header: res.header.map((item) => ({ cellFieldName: item.field, title: item.label }))
			});
			let newPrintImportButton = true;
			res.items.map((item) => (
				newPrintImportButton = newPrintImportButton && !item.error
			));
			setPrintImportButton(newPrintImportButton);
		}).catch((msg) => toastError(
			"Por favor, seleccione el excel a importar." +
			"",
			{ autoClose: false },
			true
		));
	};

	const handleChange = (data) => setFormData((prev) => ({ ...prev, ...data }));

	const handleSubmit = async () => {
		setLoadingSubmit(true);
		const res = await authenticatedFetcher({
			path: '/poll/questions/import/',
			method: 'POST',
			body: formData
		});
		setLoadingSubmit(false);
		if (!('error' in res)) {
			toastSuccess(res.response);
			if ('reload' in res) fetchData();
			toggle();
		} else {
			toastError('Error');
		}
	};

	const formFields = [
		{
			type: 'custom',
			title: 'Plantilla',
			content: (
				<a
					href={`${MEDIA_BASE_URL}/media/import-templates-examples/plantilla-importacion-preguntas-encuestas.xlsx`}
					target="_blank"
					rel="noopener noreferrer">
					Descargar plantilla de ejemplo
				</a>
			),
			labelColumn: {
				md: '4'
			},
			inputColumn: {
				md: '8'
			}
		},
		{
			type: 'hidden',
			label: '',
			name: 'poll',
			value: id,
			editable: false,
			options: {
			}
		},
		{
			type: 'file',
			value: '',
			label: t('modals.enter_the_excel_to_import'),
			name: 'file',
			editable: true,
			useLabel: true,
			labelColumn: {
				md: '4'
			},
			inputColumn: {
				md: '8'
			}
		}
	];

	return (
		<Modal toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{t('modals.import_poll_questions')}
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div className="CustomModalImportDiscount">
							<div className="row">
								<DynamicForm
									inputs={formFields}
									onChange={handleChange}
									className="w-100 col-12"
									oneColum
								/>
								<div className="col-12">
									<Button
										className="mt-3 mb-4"
										style={{ border: '1px solid', padding: '0.5rem 2rem' }}
										onClick={handleLoadFiles}>
										{t('modals.load_poll_questions')}
									</Button>
								</div>
								{tableContent && (
									<>
										<div className="col-12">
											<div className="ExportTest-table mt-5 ml-0 mr-0">
												<BasicTable
													className="pl-0 pr-0"
													header={tableContent.header}
													items={tableContent.items}
													useActions={false}
												/>
											</div>
										</div>
										{printImportButton && (
											<div className="col-12">
												<Button
													className="mt-3 mb-4"
													style={{ border: '1px solid', padding: '0.5rem 2rem' }}
													onClick={handleSubmit}>
													Importar <LoadingSpinner show={loadingSubmit} />
												</Button>
											</div>
										)}

									</>
								)}
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
