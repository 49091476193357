import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import { Types } from '../../utils/DynamicForm.config';

import InputText from './TypeForm/TypeFormTextbox';
import TextareaRich from './TypeForm/TypeFormTextareaRich';
import InputCheckbox from './TypeForm/TypeFormCheckbox';
import InputDate from './TypeForm/TypeFormInputDate';
import InputDatetime from './TypeForm/TypeFormDatetime';
import InputFile from './TypeForm/TypeFormInputFile';
import InputRadio from './TypeForm/TypeFormInputRadio';
import InputBoolean from './TypeForm/TypeFormInputBoolean';
import InputNumeric from './TypeForm/TypeFormInputNumeric';
import InputPassword from './TypeForm/TypeFormInputPassword';
import TextArea from './TypeForm/TypeFormTextarea';
import TimePicker from './TypeForm/TypeFormTimepicker';

import InputTypeMultiSelectAsync from './TypeForm/InputTypeMultiSelectAsync';
import TypeFilterInitEnd from './TypeForm/TypeFilterInitEnd';
import TypeFormColor from './TypeForm/TypeFormColor';
import Multiselect from './TypeForm/TypeMultiSelect';
import TypeFormHidden from './TypeForm/TypeFormHidden';
import TypeSelectAsync from './TypeForm/TypeSelectAsync';
import TypeSelect from './TypeForm/TypeSelect';
import TypeGallery from './TypeForm/TypeGallery';
import TypeGalleryTelegestio from './TypeForm/TypeGalleryTelegestio';
import TypeStatic from './TypeForm/TypeStatic';
import TypeIFrame from './TypeForm/TypeIFrame';
import TypeCoordinates from './TypeForm/TypeCoordinates';
import TypeGraphs from './TypeForm/TypeGraphs';
import TypeMultiCheckbox from './TypeForm/TypeMultiCheckbox';
import TypeMultiSelectInline from './TypeForm/TypeMultiSelectInline';
import TypeFormInputEmpty from './TypeForm/TypeFormInputEmpty';
import TypeFormInlineInputs from './TypeForm/TypeFormInlineInputs';

const CustomInput = (props) => {
	const {
		style,
		title,
		content,
		labelColumn = {
			xs: 6,
			md: 2
		},
		inputColumn = {
			xs: 6,
			md: 10
		},
		...otherProps
	} = props;
	const contentToRender = typeof content === 'function' ? content(otherProps) : content;
	if (!title) return contentToRender;
	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label>{title}</Label>
			</Col>
			<Col {...inputColumn}>{contentToRender}</Col>
		</FormGroup>
	);
};

const inputComponentTypeMap = {
	checkbox: InputCheckbox,
	color: TypeFormColor,
	date: InputDate,
	file: InputFile,
	radio: InputRadio,
	boolean: InputBoolean,
	integer: InputNumeric,
	password: InputPassword,
	select: TypeSelect,
	'select-async': TypeSelectAsync,
	text: InputText,
	textarea: TextArea,
	'textarea-rich': TextareaRich,
	time: TimePicker,
	datetime: InputDatetime,
	custom: CustomInput,
	multiselect: Multiselect,
	'multiselect-viewselect': Multiselect,
	'multiselect-async': InputTypeMultiSelectAsync,
	filterType: TypeFilterInitEnd,
	hidden: TypeFormHidden,
	gallery: TypeGallery,
	galleryTelegestio: TypeGalleryTelegestio,
	static: TypeStatic,
	iframe: TypeIFrame,
	coordinates: TypeCoordinates,
	graphs: TypeGraphs,
	multicheckbox: TypeMultiCheckbox,
	'multiselect-inline': TypeMultiSelectInline,
	empty: TypeFormInputEmpty,
	'inline-inputs': TypeFormInlineInputs
};

const getCustomInputProps = (inputProps) => {
	switch (inputProps.type) {
		case 'multiselect-viewselect':
			return {
				multiselect: false
			};
		default:
			return {};
	}
};

function DynamicFormInput(props) {
	const { labelColumn = {}, inputColumn = {} } = props;
	const labelColumnConfig = { md: process.env.REACT_APP_LABEL_COLUMN, className: 'inputLabel', ...labelColumn };
	const inputColumnConfig = { md: process.env.REACT_APP_INPUT_COLUMN, ...inputColumn };
	const customProps = getCustomInputProps(props);
	const Component = inputComponentTypeMap[props.type];
	return Component ? (
		<Component
			{...props}
			name={props?.name !== undefined ? props.name : ''}
			label={props?.label !== undefined ? props.label : ''}
			labelColumn={labelColumnConfig}
			inputColumn={inputColumnConfig}
			{...customProps}
		/>
	) : null;
}

DynamicFormInput.propTypes = Types.inputType;

DynamicFormInput.defaultProps = {
	onChange: () => {},
	items: [],
	type: 'text'
};

export default DynamicFormInput;
