import { buildCreateParams, validateConditions } from '../utils';
import { getMethods, searchParams } from '../services/commonServices';
import authenticatedFetcher from '../services/fetcher';

const fetchTabData = (key, urlParams = {}) => {
	return new Promise(async (resolve) => {
		try {
			const data = await authenticatedFetcher({ path: `${key}`, urlParams });
			resolve(data);
		} catch (error) {
			resolve(null);
		}
	});
};

export const useTabConfig = (tab, data, routes) => {
	const getTabData = () => {
		return new Promise((resolve) => {
			const isTabValid = validateConditions(tab.conditions, data);
			if (isTabValid) {
				const urlParams = searchParams(tab, data);
				const {
					params: toCreateParams,
					paramsFixed: toCreateParamsFixed,
					reqParams
				} = buildCreateParams(tab['params-creation']);
				const reqParams2 = searchParams({ params: reqParams }, data);
				const createFormData = searchParams(
					{ params: toCreateParams, 'params-fixed': toCreateParamsFixed },
					data
				);
				const dataUri = `/${tab.originConfig.endpoints.id}/`;
				fetchTabData(`${tab.originConfig.endpoints.list}`).then((response) => {
					const actions = getMethods(response.actions, routes);
					const newTabData = {
						title: tab.title,
						originConfig: tab,
						config: {
							...response,
							actions,
							toCreateModal: { fieldWithParams: reqParams2, data: createFormData }
						},
						params: { ...urlParams, ...tab.params },
						uri: dataUri,
						in_creation_form: 'in_creation_form' in tab ? tab.in_creation_form : false,
						tabType: tab.tab_type
					};
					resolve(newTabData);
				});
			} else {
				resolve(null);
			}
		});
	};

	return {
		getTabData
	};
};
