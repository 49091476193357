import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import BasicCoordinates from '../../BasicCoordinates/BasicCoordinates';
import { MapContainer, Marker } from 'react-leaflet';
import { RASTER_LAYERS } from '../../MapComponents/MapConfig';
import MapEventsHandler from '../../MapComponents/MapEventsHandler';
import DynamicRaster from '../../MapComponents/DynamicRaster';
import iconMarkerFromLeaflet from '../../../utils/iconMarkerFromLeaflet';

const TypeCoordinates = (props) => {
	const {
		// id,
		label,
		name,
		// value,
		// placeholder,
		onChange,
		required,
		editable = true,
		isEditing = true,
		style,
		labelColumn = {
			xs: '6',
			md: '2'
		},
		inputColumn = {
			xs: '6',
			md: '10'
		},
		alwaysEditableFields,
		formData,
		defaultValue
	} = props;

	const hiddenStyle = { visibility: 'hidden', maxHeight: '1px', overflow: 'hidden' };
	const visibleStyle = { visibility: 'visible', maxHeight: 'none', overflow: 'auto' };

	const [markerData, setmarkerData] = useState();
	const [refreshCount, setRefreshCount] = useState(0);
	const [xProps, setXProps] = useState({
		label: 'X',
		name: 'location_lon',
		labelColumn: {
			md: '1'
		},
		type: 'integer',
		editable
	});
	const [yProps, setYProps] = useState({
		label: 'Y',
		name: 'location_lat',
		labelColumn: {
			md: '1'
		},
		type: 'integer',
		editable
	});
	useEffect(() => {
		if (formData[`${name}_lon`] && formData[`${name}_lat`])
			setmarkerData({
				lng: formData[`${name}_lon`],
				lat: formData[`${name}_lat`]
			});
		else if (defaultValue) {
			const [dX, dY] = defaultValue.split('-');
			if (dX && dY)
				setmarkerData({
					lng: dX,
					lat: dY
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (markerData) {
			setXProps((prev) => ({ ...prev, value: markerData.lng }));
			setYProps((prev) => ({ ...prev, value: markerData.lat }));
			onChange(
				{
					target: {
						[`${name}_lon`]: markerData.lng,
						[`${name}_lat`]: markerData.lat
					}
				},
				{ isObject: true }
			);
			setRefreshCount((prev) => prev + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markerData]);

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable && isEditing;
	const selectsColumns = inputColumn
		? { md: { size: inputColumn.md, offset: 12 - inputColumn.md } }
		: { md: { size: 10, offset: 2 } };

	const handleSetMarker = (e) => isEditable && setmarkerData(e.latlng);

	const handleInputChange = (newValue) => {
		if (!newValue.target.value) return setmarkerData(null);
		const propName = newValue.target.name === `${name}_lon` ? 'lng' : 'lat';
		setmarkerData((prev) => ({
			...prev,
			lng: xProps.value,
			lat: yProps.value,
			[propName]: newValue.target.value
		}));
	};

	return (
		<FormGroup tag="fieldset" row>
			{label !== false ? (
				<Col {...labelColumn} className={labelColumn.className}>
					<Label style={style} for="form-2-last-name">
						{required ? '*' : ''} {label}
					</Label>
				</Col>
			) : (
				''
			)}
			<Col {...inputColumn} className={inputColumn.className}>
				<BasicCoordinates coordX={xProps} coordY={yProps} isEditing={isEditable} onChange={handleInputChange} />
			</Col>
			<Col {...selectsColumns} style={isEditable ? visibleStyle : hiddenStyle}>
				<MapContainer
					center={[markerData?.lat || 41.35, markerData?.lng || 2.08333]}
					className="CustomMap-mini"
					zoom={9}>
					{markerData && markerData.lat && markerData.lng && (
						<Marker icon={iconMarkerFromLeaflet()} position={markerData} key={refreshCount} />
					)}
					<DynamicRaster {...RASTER_LAYERS[0]} />
					<MapEventsHandler onClick={handleSetMarker} />
				</MapContainer>
			</Col>
		</FormGroup>
	);
};

TypeCoordinates.propTypes = {};

export default TypeCoordinates;
